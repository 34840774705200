<link href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css" rel="stylesheet">
<header class="admin-header">
  <nav class="navbar navbar-expand-lg">
    <div class="container align-items-end">
      <a [routerLink]="['/home']" *ngIf="isStudent"><img src="assets/images/site/small-logo.png" alt="Logo"></a>
      <a [routerLink]="['/admin']" *ngIf="!isStudent"><img src="assets/images/site/small-logo.png" alt="Logo"></a>

      <div class="header-btn">
        <ul>
          <li>
            <a [routerLink]="['/home/skill/add']" class="btn btn-themePrimary btn-icon" *ngIf="isStudent"><i
                class="fa-solid fa-circle-plus"></i></a>
          </li>
          <li>
            <div class="btn-group" dropdown #dropdown="bs-dropdown">
              <div style="width: 100%;z-index:998;">
                <button id="dropdownBasic1" type="button" dropdownToggle
                  class="btn btn-themelight1 btn-iconText dropdown-toggle" aria-controls="dropdown-basic"><i
                    class="fa-solid fa-user"></i>{{userName}}
                </button>
              </div>
              <div>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem" *ngIf="isAuthenticated && !isStudent">
                    <a class="dropdown-item" [routerLink]="['/home/skill/add']"><i class="fa fa-user"></i> Portfolio</a>
                  </li>
                  <li role="menuitem" *ngIf="isAuthenticated && !isStudent">
                    <a class="dropdown-item" [routerLink]="['/admin']"><i class="fa fa-gear"></i> Admin</a>
                  </li>
                  <li role="menuitem" *ngIf="isAuthenticated">
                    <a class="dropdown-item" (click)="logout()"><i class="fa fa-lock"></i> Logout</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li><a href="javascript:void(0)" routerLink="/home/print" class="btn btn-theme1 btn-iconText">
              <i class="fa-solid fa-print"></i>
              Print</a>
          </li>
        </ul>
      </div>
    </div>

  </nav>
</header>